import Modal from "react-bootstrap/Modal";
import React, { useEffect, useRef, useState } from "react";
import closeIcon from "../assets/closeIcon3.svg";
import SelectBox from "./SelectBox";
import AddIcon from "../assets/plus.svg";
import M3Icon from "../assets/m3.svg";
import TrashIcon from "../assets/delete.svg";
import { useSelector } from "react-redux";
import UploadIcon from "../assets/upload.svg";
import { toast } from "react-toastify";
import DeleteIcon from "../assets/delete.svg";
import {
  enginesByVesselId,
  fuelTankByVesselId,
  energyConsumptionByVesselId,
  updateVessel,
  addFuelTank,
  addEngines,
  addEnergyConsumption,
  allVesselType,
  getAllFuelFamilies,
  updateVesselEngines,
} from "../api/data";
import useNumericInput from "../hooks/useNumericInput";
import { capitalizeAllLetters, formatNumber } from "../utils/formatter";

export const CommonOverlayModal = ({
  showOverlayModal,
  onCloseOverlayModal,
  modalSection,
  vesselInfo,
  fuelTankData,
  energyConsumptionData,
  engineData,
}) => {
  const selectedVessel = useSelector(
    (state) => state.selectedVesselItem.selectedVessel
  );
  const [showSpin, setShowSpin] = useState(false);

  const [engineType, setEngineType] = useState([]);
  const [engineSubType, setEngineSubType] = useState([]);
  const [enginesBom, setEnginesBom] = useState([
    { id: 1, item: "1" },
    { id: 2, item: "2" },
    { id: 3, item: "3" },
    { id: 4, item: "4" },
  ]);

  const [vesselActivity, setVesselActivity] = useState([]);
  const [vesselModelType, setVesselModelType] = useState([]);

  const [vesselType, setVesselType] = useState({ id: null, item: "Select" });
  const [mainFuel, setMainFuel] = useState({ id: null, item: "Select" });
  const [vesselName, setVesselName] = useState(
    vesselInfo?.vesselName?.target?.value ?? vesselInfo?.vesselName
  );
  const [vesselTypeId, setVesselTypeId] = useState(vesselInfo?.vesselType.id);
  const [fuelFamilyId, setFuelFamilyId] = useState(vesselInfo?.fuelFamily.id);
  const [capEx, setCapEx] = useState(
    vesselInfo?.extraCapex?.target?.value ?? vesselInfo?.extraCapex
  );
  const [rotationValue, setRotationValue] = useState(
    vesselInfo?.rotation?.target?.value ?? vesselInfo?.rotation
  );
  const [hasScrubber, setHasScrubber] = useState(
    vesselInfo?.hasScrubber?.target?.value ?? vesselInfo?.hasScrubber
  );
  const [hasEGRSCR, setHasEGRSCR] = useState(vesselInfo?.hasEGRSCR);
  const { handleOnKeyDown } = useNumericInput();

  const [fuelCompatibility1, setFuelCompatibility1] = useState(null);
  const [fuelCompatibility2, setFuelCompatibility2] = useState(null);

  const [showErrorCompatibility1, setShowErrorCompatibility1] = useState(false);
  const [showErrorCompatibility2, setShowErrorCompatibility2] = useState(false);
  const [viewCapacity, setViewCapacity] = useState("");
  const [showCapacityError, setShowCapacityError] = useState(false);
  const [errorMessageCapacity, setErrorMessageCapacity] = useState("");
  const [isTouchedCapacity, setIsTouchedCapacity] = useState(false);

  const [fuelTanks, setFuelTanks] = useState([]);
  const [selectedEngineType, setSelectedEngineType] = useState({
    id: "",
    item: "Select",
  });
  const [selectedEngineSubType, setSelectedEngineSubType] = useState({
    id: "",
    item: "Select",
  });
  const [selectedBom, setSelectedBom] = useState({ id: null, item: "Select" });

  const [engines, setEngines] = useState([]);
  const [showEngineTypeError, setShowEngineTypeError] = useState(false);
  const [showEngineSubTypeError, setShowEngineSubTypeError] = useState(false);
  const [showBomError, setShowBomError] = useState(false);

  const [selectedActivity, setSelectedActivity] = useState({
    id: "",
    item: "Select",
  });
  const [selectedModelType, setSelectedModelType] = useState({
    id: "",
    item: "Select",
  });
  const [energyRequirements, setEnergyRequirements] = useState("");
  const [energyConsumptions, setEnergyConsumptions] = useState([]);

  const [vesselTypeList, setVesselTypeList] = useState([]);
  const [fuelFamilyList, setFuelFamilyList] = useState([]);

  const fileInputRef = useRef(null);

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    // the file we get when we click on browse
    const file = event.target.files[0];
  };

  const [energyConsumptionsBody, setEnergyConsumptionsBody] = useState([]);
  const [fuelTankBody, setFuelTankBody] = useState([]);
  const [enginesBody, setEnginesBody] = useState([]);

  const [vesselNameError, setVesselNameError] = useState(null);
  const [capExError, setCapExError] = useState(null);
  const [rotationError, setRotationError] = useState(null);

  const [showRequirementError, setShowRequirementError] = useState(false);
  const [requirementError, setRequirementError] = useState(null);

  const getAllFuelFamily = () => {
    getAllFuelFamilies()
      .then((result) => {
        const fuelFamilyList = result.data.map((v, index) => ({
          id: v?.id,
          item: v?.fuelFamilyName,
        }));
        setFuelFamilyList(fuelFamilyList);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err get company name", err);
      });
  };

  const getAllVesselTypes = () => {
    allVesselType()
      .then((result) => {
        const vesselTypeList = result.data.map((v, index) => ({
          id: v?.id,
          item: v?.vesselTypeName,
        }));
        setVesselTypeList(vesselTypeList);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err get company name", err);
      });
  };

  const isDisabledSaveButton = (type) => {
    switch (type) {
      case "engine":
        if (!enginesBody || enginesBody.length === 0) {
          return true;
        } else {
          return false;
        }
      case "fuel-tanks":
        if (!fuelTankBody || fuelTankBody.length === 0) {
          return true;
        } else {
          return false;
        }
      case "energy-consumption":
        if (!energyConsumptionsBody || energyConsumptionsBody.length === 0) {
          return true;
        } else {
          return false;
        }
      default:
        break;
    }
  };

  const handleSaveEngines = () => {
    if (!enginesBody || enginesBody.length === 0) {
      toast.error("Please add engines");
      return;
    }
    addEngines(selectedVessel?.id, enginesBody)
      .then((res) => {
        console.log(res.data);
        toast.success("Engines has been updated.", {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          toastId: "update_price_template",
        });
        setShowSpin(false);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("error save engines", err);
      });
  };

  const handleSaveFuelTanks = () => {
    if (!fuelTankBody || fuelTankBody.length === 0) {
      toast.error("Please add fuel tanks");
      return;
    }
    addFuelTank(selectedVessel?.id, fuelTankBody)
      .then((res) => {
        console.log(res.data);
        toast.success("Vessel has been updated.", {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          toastId: "update_price_template",
        });
        setShowSpin(false);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("error update scenario", err);
      });
  };

  const handleSaveEnergyConsumption = () => {
    if (!energyConsumptionsBody || energyConsumptionsBody.length === 0) {
      toast.error("Please add energy consumptions");
      return;
    }
    addEnergyConsumption(selectedVessel?.id, energyConsumptionsBody)
      .then((res) => {
        console.log(res.data);
        toast.success("Energy consumption has been updated.", {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          toastId: "update_price_template",
        });
        setShowSpin(false);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("error update energy consumption", err);
      });
  };

  const handleActivityChange = (item) => {
    setSelectedActivity(item);
  };

  const handleModelTypeChange = (item) => {
    setSelectedModelType(item);
  };

  const handleRequirementChange = (e) => {
    const value = e.target.value;
    if (isNaN(value) || value < 0) {
      setShowRequirementError(true);
      setRequirementError("Enter correct power requirement");
      setEnergyRequirements("");
    } else {
      setShowRequirementError(false);
      setEnergyRequirements(value);
      setRequirementError("");
    }
  };

  const handleRequirementFocus = () => {
    setShowRequirementError(false);
  };

  const handleAddField = () => {
    if (
      selectedActivity.id &&
      selectedModelType.id &&
      energyRequirements !== ""
    ) {
      const newEntry = {
        va: { name: selectedActivity?.item ?? "" },
        est: { estname: selectedModelType?.item ?? "" },
        powerReqKw: energyRequirements ?? 0,
      };

      const newEntryBody = {
        vesselActivityId: selectedActivity.id,
        engineSubTypeId: selectedModelType.id,
        powerRequirement_Kw_hr: energyRequirements,
      };

      setEnergyConsumptions([...energyConsumptions, newEntry]);
      setEnergyConsumptionsBody([...energyConsumptionsBody, newEntryBody]);
      setSelectedActivity({ id: "", item: "Select" });
      setSelectedModelType({ id: "", item: "Select" });
      setEnergyRequirements("");
      setShowRequirementError(false);
    } else {
      if (!selectedActivity.id) {
        setShowRequirementError(true);
      }
      if (!selectedModelType.id) {
        setShowRequirementError(true);
      }
      if (energyRequirements === "") {
        setShowRequirementError(true);
      }
    }
  };

  const handleDeleteField = (index) => {
    const deletedItemId = energyConsumptions[index]?.id;
    const updatedConsumptions = energyConsumptions.filter(
      (_, i) => i !== index
    );
    const updatedConsumptionsBody = energyConsumptionsBody.filter(
      (item) => item.id !== deletedItemId
    );
    setEnergyConsumptions(updatedConsumptions);
    setEnergyConsumptionsBody(updatedConsumptionsBody);
  };

  const handleEngineTypeChange = (item) => {
    setSelectedEngineType(item);
    setShowEngineTypeError(false);
  };

  const handleEngineSubTypeChange = (item) => {
    setSelectedEngineSubType(item);
    setShowEngineSubTypeError(false);
  };

  const handleBomChange = (item) => {
    setSelectedBom(item);
    setShowBomError(false);
  };

  const handleAddEngine = () => {
    if (selectedEngineType.id && selectedEngineSubType.id && selectedBom.id) {
      const newEngine = {
        est: {
          estname: selectedEngineSubType.item ?? "",
          myEngineClass: {
            etname: selectedEngineType.item ?? "",
          },
        },
        installed: selectedBom.item ?? false,
      };
      const newEngineBody = {
        estId: selectedEngineSubType.id,
        installed: selectedBom.item,
      };
      setEngines([...engines, newEngine]);
      setSelectedEngineType({ id: "", item: "Select" });
      setSelectedEngineSubType({ id: "", item: "Select" });
      setSelectedBom({ id: "", item: "Select" });
      setEnginesBody([...enginesBody, newEngineBody]);
    } else {
      if (!selectedEngineType.id) setShowEngineTypeError(true);
      if (!selectedEngineSubType.id) setShowEngineSubTypeError(true);
      if (!selectedBom.id) setShowBomError(true);
    }
  };

  const handleDeleteEngine = (id) => {
    const updatedEngines = engines.filter((engine) => engine.id !== id);
    const updatedEnginesBody = enginesBody.filter((engine) => engine.id !== id);
    setEngines(updatedEngines);
    setEnginesBody(updatedEnginesBody);
  };

  const handleChangeFuelCompatibility1 = (selectedItem) => {
    setFuelCompatibility1(selectedItem);
    setShowErrorCompatibility1(false);
  };
  
  const handleChangeFuelCompatibility2 = (selectedItem) => {
    setFuelCompatibility2(selectedItem);
    setShowErrorCompatibility2(false);
  };
  
  const handleAddTank = () => {
    if (!fuelCompatibility1 || !fuelCompatibility2) {
      setShowErrorCompatibility1(!fuelCompatibility1);
      setShowErrorCompatibility2(!fuelCompatibility2);
      return;
    }
    
  const newTank = {
    capacityM3: parseFloat(viewCapacity), 
    id: Date.now(),
    sharedByFuelFamilies: [
      {
        fuelFamilyName: fuelCompatibility1.item || "Unknown",
        id: fuelCompatibility1.id || 0,
      },
      {
        fuelFamilyName: fuelCompatibility2.item || "Unknown",
        id: fuelCompatibility2.id || 0,
      }
    ]
  };
  
  setFuelTanks([...fuelTanks, newTank]);
  const newFuelTankBody = {
    capacitym3: newTank.capacityM3,
    fuelFamilyId: [
      fuelCompatibility1.id || 0,
      fuelCompatibility2.id || 0
    ]
  };

  setFuelTankBody(newFuelTankBody);    
  setFuelCompatibility1(null);
  setFuelCompatibility2(null);
  };

  const handleCapacityFocus = () => {
    setIsTouchedCapacity(true);
  };

  const handleCapacityChange = (e) => {
    let stringValue = e.target.value ? e.target.value.toString() : "";
    const maxCapacity = 10000;
    const maxDigitsBeforeDecimal = 5;
    const errorMessageCapacity = `Capacity must be between 0 to ${maxCapacity}`;

    const regex = new RegExp(
      `^\\d{0,${maxDigitsBeforeDecimal}}(\\.\\d{0,2})?$`
    );

    if (!regex.test(stringValue)) {
      const [integerPart, decimalPart] = stringValue.split(".");
      let slicedValue = integerPart.slice(0, maxDigitsBeforeDecimal);
      if (decimalPart) {
        slicedValue += "." + decimalPart.slice(0, 2);
      }
      stringValue = slicedValue;
    }
    const value = parseFloat(stringValue);
    if (isNaN(value) || value < 0 || value > maxCapacity) {
      setViewCapacity(stringValue);
      setErrorMessageCapacity(errorMessageCapacity);
    } else {
      setViewCapacity(stringValue);
      setErrorMessageCapacity("");
    }
  };

  const handleChangeAfterAdd = (e, tank, index) => {
    const updatedTank = { ...tank, capacityM3: parseInt(e.target.value) };
  
    setFuelTanks(prevFuelTanks => {
      const updatedFuelTanks = [...prevFuelTanks];
      updatedFuelTanks[index] = updatedTank;
      return updatedFuelTanks;
    });
  };

  const handleDeleteTank = (tankId) => {
    const updatedTanks = fuelTanks.filter((tank) => tank.id !== tankId);
    setFuelTanks(updatedTanks);
  };


  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const getVesselType = () => {
    try {
      const vesselTypeData = {
        id: vesselInfo?.vesselType?.id,
        item: vesselInfo?.vesselType?.vesselTypeName,
      };
      setVesselType(vesselTypeData);
    } catch (err) {
      setError(err);
      setOpen(true);
      console.log("Error in getting vessel type data:", err);
    }
  };

  const getMainFuel = () => {
    try {
      const mainFuel = {
        id: vesselInfo?.fuelFamily?.id,
        item: vesselInfo?.fuelFamily?.fuelFamilyName,
      };
      setMainFuel(mainFuel);
    } catch (err) {
      setError(err);
      setOpen(true);
      console.log("Error in getting vessel type data:", err);
    }
  };

  const getEngines = (vesselId) => {
    enginesByVesselId(vesselId)
      .then((res) => {
        const engineType = res.data.map((s, index) => ({
          id: s?.est?.myEngineClass?.id,
          item: capitalizeAllLetters(s?.est?.myEngineClass?.etname),
        }));
        setEngineType(engineType);
        const engineSubType = res.data.map((s, index) => ({
          id: s?.est?.id,
          item: s?.est?.estname,
        }));
        setEngineSubType(engineSubType);
        const engineBom = res.data.map((s, index) => ({
          id: s?.id,
          item: s?.installed,
        }));
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("error in getting fuel tank", err);
      });
  };

  const getEnergyConsumption = (vesselId) => {
    energyConsumptionByVesselId(vesselId)
      .then((res) => {
        const vesselActivity = res.data.map((s, index) => ({
          id: s?.va?.id,
          item: s?.va?.name,
        }));
        setVesselActivity(vesselActivity);
        const modelType = res.data.map((s, index) => ({
          id: s?.est?.id,
          item: s?.est.estname,
        }));
        setVesselModelType(modelType);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("error in getting energy consumption", err);
      });
  };

  useEffect(() => {
    if (selectedVessel?.id) {
      getEngines(selectedVessel.id);
      getEnergyConsumption(selectedVessel.id);
      getVesselType();
      getMainFuel();
      getAllVesselTypes();
      getAllFuelFamily();
    }
  }, [selectedVessel]);

  useEffect(() => {
    async function fetchData() {
      setFuelTanks(fuelTankData);
      setEngines(engineData);
      setEnergyConsumptions(energyConsumptionData);
    }

    fetchData();
  }, [fuelTankData, engineData, energyConsumptionData]);

  const handleUpdateVessel = () => {
    const transformedData = {
      name: vesselName,
      vesselTypeId: vesselTypeId,
      fuelFamilyId: fuelFamilyId,
      capEx: capEx,
      rotation: rotationValue,
      hasScrubber: hasScrubber,
      hasEGRSCR: hasEGRSCR,
    };

    updateVessel(transformedData, vesselInfo.id)
      .then((res) => {
        console.log(res.data);
        toast.success("Vessel has been updated.", {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          toastId: "update_price_template",
        });
        setShowSpin(false);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("error update scenario", err);
      });
  };

  const handleVesselNameChange = (e) => {
    const name = e.target.value;
    const textOnlyRegex = /^[a-zA-Z\s]*$/;

    if (name.length > 20) {
      setVesselNameError("Name must be less than 20 characters");
    } else if (!textOnlyRegex.test(name)) {
      setVesselNameError("Name should contain only alphabates");
    } else {
      setVesselNameError("");
      setVesselName(name);
    }
  };

  const handleVesselTypeChange = (item) => {
    setVesselType(item);
    setVesselTypeId(item.id);
  };

  const handleMainFuelChange = (item) => {
    setMainFuel(item);
    setFuelFamilyId(item.id);
  };

  const handleCapExChange = (e) => {
    const capEx = e.target.value;
    const numberOnlyRegex = /^[0-9,]*$/;

    if (numberOnlyRegex.test(capEx)) {
      setCapEx(capEx);
      setCapExError("");
    } else {
      setCapExError("Please enter a valid number");
    }
  };

  const handleRotationChange = (e) => {
    const capitalLetterRegex = /^[A-Z]?$/;
    const rotation = e.target.value;

    if (!capitalLetterRegex.test(rotation)) {
      setRotationError("Rotation should be a single capital letter");
    } else if (rotation.length > 1) {
      setRotationError("Rotation should be a single capital letter");
    } else {
      setRotationValue(rotation);
      setRotationError("");
    }

    setRotationValue(rotation);
  };

  const handleScrubberChange = (e) => {
    const scrubberValue = e.target.value;
    if (scrubberValue === "on") setHasScrubber(true);
    else {
      setHasScrubber(false);
    }
  };

  const handleEGRSCRChange = (e) => {
    const egrscrValue = e.target.value;
    if (egrscrValue === "on") setHasEGRSCR(true);
    else {
      setHasEGRSCR(false);
    }
  };

  return (
    <Modal
      show={showOverlayModal}
      onHide={onCloseOverlayModal}
      aria-labelledby="engines-modal-title"
      aria-describedby="engine-modal-description"
      centered
      size="md"
      className="bg-none"
    >
      <Modal.Body>
        {modalSection === "engine" && (
          <>
            <div className="mb-2">
              <span className="input-label">Engines</span>
              <div
                className="close-modalSection"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  onCloseOverlayModal();
                  setEnginesBody([]);
                  setSelectedBom({ id: null, item: "Select" });
                }}
              >
                <img src={closeIcon} alt="Close Icon" />
              </div>
            </div>

            <div className="engine-modal-main gap-4 w-100">
              <div className="d-flex justify-content-between w-100 gap-2">
                <div className="">
                  <p className="input-label pop-up-title mb-2">Engine Type</p>
                  <SelectBox
                    selectedItem={selectedEngineType}
                    listItems={engineType}
                    disabledOptions={[]}
                    showError={showEngineTypeError}
                    setShowError={setShowEngineTypeError}
                    setSelectedItem={handleEngineTypeChange}
                    showAddElement={false}
                    className="select-box-v2 select-box-v2-small"
                  />
                </div>

                <div className="">
                  <p className="input-label pop-up-title mb-2">Model Type</p>
                  <SelectBox
                    selectedItem={selectedEngineSubType}
                    listItems={engineSubType}
                    setSelectedItem={handleEngineSubTypeChange}
                    disabledOptions={[]}
                    showError={showEngineSubTypeError}
                    setShowError={setShowEngineSubTypeError}
                    showAddElement={false}
                    className="select-box-v2 select-box-v2-medium"
                  />
                </div>

                <div className="">
                  <p className="input-label pop-up-title mb-2">Quantity</p>
                  <SelectBox
                    selectedItem={selectedBom}
                    listItems={enginesBom}
                    disabledOptions={[]}
                    setSelectedItem={handleBomChange}
                    showError={showBomError}
                    setShowError={setShowBomError}
                    showAddElement={false}
                    className="select-box-v2 select-box-v2-small"
                  />
                </div>

                <div className="mt-4">
                  <button
                    className="btn btn-primary add secondary"
                    onClick={handleAddEngine}
                  >
                    <img src={AddIcon} alt="" />
                  </button>
                </div>
              </div>

              <div
                className={`selected-engines ${
                  engines?.length > 0 ? "with-border" : ""
                } mt-3`}
              >
                {engines?.length > 0 && (
                  <div className="ht-9 overflow-y-auto">
                    {engines?.map((engine, index) => (
                      <div className="d-flex w-100 gap-3 mt-3" key={index}>
                        <div className="">
                          <div
                            className="rounded-5 outline-0 grey-border model-padding input-v2-small"
                          >{capitalizeAllLetters(
                            engine.est?.myEngineClass.etname
                          )}</div>

                        </div>
                        <div className="w-100">
                          <div className="w-100 model-padding rounded-5 outline-0 grey-border">
                          {engine.est?.estname}
                          </div>
                        </div>
                        <div className="">
                            <div className="rounded-5 outline-0 grey-border model-padding input-v2-small">{engine.installed}</div>
                        </div>
                        <button
                          className="btn secondary h-100 wid-10"
                          onClick={() => handleDeleteEngine(engine.id)}
                        >
                          <img src={TrashIcon} alt="" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="actions bg-none text-end">
              <button
                className="btn btn-primary btn-lg fs-16"
                onClick={handleSaveEngines}
                disabled={isDisabledSaveButton("engine")}
              >
                Save
                {showSpin && (
                  <div className="spinner-border" role="status"></div>
                )}
              </button>
            </div>
          </>
        )}
        {modalSection === "fuel" && (
          <>
            <div className="mb-2">
              <span className="input-label">Fuel Tanks</span>
              <div
                className="close-modalSection"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  onCloseOverlayModal();
                  setFuelTankBody([]);
                }}
              >
                <img src={closeIcon} alt="Close Icon" />
              </div>
            </div>

            <div className="engine-modal-main gap-4 w-100 px-3 py-3">
              <div className="w-100">
                <div className="d-flex w-100 gap-3 align-items-center">
                  <div className="input-group w-35">
                    <p className="input-label pop-up-title mb-2">
                      Total Capacity
                    </p>
                    <div className="mail-input pos-relative parameter">
                      <img src={M3Icon} alt="" />
                      <input
                        type="text"
                        placeholder="Enter Value"
                        name="Enter Capacity"
                        className={`rounded-5 outline-0 bg-white ${
                          showCapacityError ? "error-input" : ""
                        }`}
                        onFocus={handleCapacityFocus}
                        value={viewCapacity || ""}
                        onChange={handleCapacityChange}
                        onKeyDown={handleOnKeyDown}
                      />
                    </div>
                  </div>
                  <div className="input-group w-47">
                    <p className="input-label pop-up-title mb-2">
                      Fuel Compatibility 1
                    </p>
                    <SelectBox
                      selectedItem={fuelCompatibility1 || { id: "", item: "Select" }}
                      listItems={fuelFamilyList}
                      setSelectedItem={handleChangeFuelCompatibility1}
                      showError={showErrorCompatibility1}
                      setShowError={setShowErrorCompatibility1}
                      className="select-box-v2"
                    />
                  </div>
                  <div className="input-group w-47">
                    <p className="input-label pop-up-title mb-2">
                      Fuel Compatibility 2
                    </p>
                    <SelectBox
                      selectedItem={fuelCompatibility2 || { id: "", item: "Select" }}
                      listItems={fuelFamilyList}
                      setSelectedItem={handleChangeFuelCompatibility2}
                      showError={showErrorCompatibility2}
                      setShowError={setShowErrorCompatibility2}
                      className="select-box-v2"
                    />
                  </div>
                  <button
                    className="btn btn-primary add secondary h-100 mt-3"
                    onClick={handleAddTank}
                  >
                    <img src={AddIcon} alt="" />
                  </button>
                </div>
                {isTouchedCapacity && errorMessageCapacity && (
                  <p className="error mb-0 h-auto text-end px-5">
                    {errorMessageCapacity}
                  </p>
                )}
              </div>
              <div
                className={`selected-tanks ${
                  fuelTanks?.length > 0 ? "with-border" : ""
                } mt-3`}
              >
                {fuelTanks?.length > 0 && (
                  <div className="ht-9 overflow-y-auto">
                    {fuelTanks?.map((tank, index) => (
                      <div className="d-flex w-100 gap-3 mt-2" key={index}>
                        <div className="w-35 mail-input pos-relative parameter">
                          <input
                            type="text"
                            defaultValue={tank.capacityM3}
                            onChange={(e) => handleChangeAfterAdd(e, tank, index)}
                            className="w-100 outline-0 grey-border"
                            onKeyDown={handleOnKeyDown}
                          />
                          <img
                            src={M3Icon}
                            alt="unit icon"
                            className="m3-unit-icon"
                          />
                        </div>
                        <div className="w-47">
                          <div className="w-100 info-div grey-border">
                            {tank.sharedByFuelFamilies[0]?.fuelFamilyName}
                          </div>
                        </div>
                        <div className="w-47">
                          <div className={`w-100 info-div outline-0 grey-border ${tank.sharedByFuelFamilies[1]?"":"dark-grey"}`}>
                          {tank.sharedByFuelFamilies[1]?.fuelFamilyName ?? "No Data"}
                          </div>
                        </div>
                        <button
                          className="btn secondary h-100 wid-10"
                          onClick={() =>
                            handleDeleteTank(tank.id)
                          }
                        >
                          <img src={TrashIcon} alt="Delete Tank" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="actions bg-none text-end">
              <button
                className="btn btn-primary btn-lg fs-16"
                onClick={handleSaveFuelTanks}
                disabled={isDisabledSaveButton("fuel-tanks")}
              >
                Save
                {showSpin && (
                  <div className="spinner-border" role="status"></div>
                )}
              </button>
            </div>
          </>
        )}
        {modalSection === "energy" && (
          <>
            <div className="mb-2">
              <span className="input-label">Energy Consumption</span>
              <div
                className="close-modalSection"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  onCloseOverlayModal();
                  setEnergyConsumptionsBody([]);
                }}
              >
                <img src={closeIcon} alt="Close Icon" />
              </div>
            </div>

            <div className="engine-modal-main gap-4 w-100">
              <div className="d-flex gap-3">
                <div className="w-25">
                  <p className="input-label pop-up-title mb-2">Activity</p>
                  <SelectBox
                    selectedItem={selectedActivity}
                    listItems={vesselActivity}
                    disabledOptions={[]}
                    showError={false}
                    setShowError={() => {}}
                    showAddElement={false}
                    className="select-box-v2 rounded-5"
                    setSelectedItem={handleActivityChange}
                  />
                </div>
                <div className="w-50">
                  <p className="input-label pop-up-title mb-2">Model Type</p>
                  <SelectBox
                    selectedItem={selectedModelType}
                    listItems={vesselModelType}
                    setSelectedItem={handleModelTypeChange}
                    disabledOptions={[]}
                    showError={false}
                    setShowError={() => {}}
                    showAddElement={false}
                    className="select-box-v2 rounded-5"
                  />
                </div>

                <div className="w-25 mail-input pos-relative parameter">
                  <p className="input-label pop-up-title mb-2">
                    Requirement/hr
                  </p>
                  <input
                    type="number"
                    className={`rounded-5 outline-0 bg-white ${
                      showRequirementError ? "error-input" : ""
                    }`}
                    placeholder="Enter Requirement/hr"
                    value={energyRequirements}
                    onChange={handleRequirementChange}
                    onFocus={handleRequirementFocus}
                  />
                  {requirementError && (
                    <p className="error text-start mb-0">{requirementError}</p>
                  )}
                </div>
                <div className="mt-4">
                  <button
                    className="btn btn-primary add secondary"
                    onClick={handleAddField}
                  >
                    <img src={AddIcon} alt="Add Icon" />
                  </button>
                </div>
              </div>
              <div
                className={`selected-activities mt-2 ${
                  energyConsumptions?.length > 0 ? "with-border" : ""
                }`}
              >
                {energyConsumptions?.length > 0 && (
                  <div className="ht-9 overflow-y-auto">
                    {energyConsumptions?.map((entry, index) => (
                      <div
                        className="d-flex gap-3 align-items-center mt-2"
                        key={index}
                      >
                        <div className="w-25">
                          <div className="w-100 model-padding rounded-5 outline-0 grey-border">
                            {entry.va?.name}
                          </div>
                        </div>
                        <div className="w-50">
                          <div className="w-100 model-padding rounded-5 outline-0 grey-border">
                            {entry.est?.estname}
                          </div>
                        </div>
                        <div className="w-25">
                          <input
                            type="text"
                            defaultValue={entry.powerReqKw}
                            className="w-100 model-padding rounded-5 outline-0 grey-border"
                            onKeyDown={handleOnKeyDown}
                          />
                        </div>
                        <button
                          className="btn secondary h-100 wid-10   "
                          onClick={() => handleDeleteField(index)}
                        >
                          <img src={TrashIcon} alt="Delete Icon" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="actions bg-none text-end mt-2">
              <button
                className="btn btn-primary btn-lg fs-16"
                onClick={handleSaveEnergyConsumption}
                disabled={isDisabledSaveButton("energy-consumption")}
              >
                Save
                {showSpin && (
                  <div className="spinner-border" role="status"></div>
                )}
              </button>
            </div>
          </>
        )}

        {modalSection === "edit" && (
          <>
            <div className="mb-2 bg-none">
              <span className="input-label">Vessel Editor</span>
              <div
                className="close-modalSection"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  onCloseOverlayModal();
                }}
              >
                <img src={closeIcon} alt="Close Icon" />
              </div>
            </div>
            <div className="engine-modal-main justify-content-between gap-4 w-100">
              <div className="d-flex align-items-center mb-2 gap-2">
                <span className="">Vessel Name:</span>
                <div className="d-flex flex-column">
                  <input
                    className="input-box p-2 outline-0"
                    defaultValue={vesselName}
                    onChange={(e) => handleVesselNameChange(e)}
                  />
                  {vesselNameError && (
                    <p className="error text-start mb-0">{vesselNameError}</p>
                  )}
                </div>
                <img src={DeleteIcon} alt="" />
              </div>
              <div className="gap-2">
                <div className="d-flex">
                  <div className="gap-3 d-flex align-items-center">
                    <p className="m-0">Vessel Type</p>
                    <SelectBox
                      selectedItem={
                        vesselType || { id: "", name: "Select an option" }
                      }
                      listItems={vesselTypeList}
                      disabledOptions={[]}
                      showError={false}
                      setShowError={() => {}}
                      showAddElement={true}
                      className="select-box-v2 fixed-width-dropdown p-2"
                      setSelectedItem={handleVesselTypeChange}
                    />
                  </div>

                  <div className="gap-3 d-flex align-items-center">
                    <p className="m-0">CapEx</p>
                    <div>
                      <span className="capExIcon">$</span>
                      <input
                        className="input-box-2 p-2 px-4 w-100 m-0 outline-0"
                        defaultValue={`${formatNumber(capEx)}`}
                        onChange={(e) => handleCapExChange(e)}
                      />
                      {capExError && (
                        <p className="error text-start mb-0">{capExError}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="gap-3 d-flex align-items-center">
                    <p className="m-0">Main Fuel</p>
                    <SelectBox
                      selectedItem={
                        mainFuel || { id: "", name: "Select an option" }
                      }
                      listItems={fuelFamilyList}
                      disabledOptions={[]}
                      showError={false}
                      setShowError={() => {}}
                      showAddElement={true}
                      className="select-box-v2 fixed-width-dropdown p-2"
                      setSelectedItem={handleMainFuelChange}
                    />
                  </div>
                  <div className="gap-3 d-flex align-items-center">
                    <p className="m-0">Rotation</p>
                    <input
                      className="input-box p-2 m-0 w-100 outline-0"
                      defaultValue={rotationValue ?? "No rotations"}
                      onChange={(e) => handleRotationChange(e)}
                    />
                    {rotationError && (
                      <p className="error text-start mb-0">{rotationError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-2 d-flex align-items-center gap-3">
                <div className="d-flex align-items-center gap-2">
                  <input
                    className="form-check-input outline-0 m-0"
                    type="checkbox"
                    checked={hasEGRSCR || false}
                    onChange={(e) => handleEGRSCRChange(e)}
                  />
                  <label className="form-check-label" htmlFor="inlineCheckbox1">
                    EGR/SCR
                  </label>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <input
                    className="form-check-input outline-0 m-0"
                    type="checkbox"
                    checked={hasScrubber || false}
                    onChange={(e) => handleScrubberChange(e)}
                  />
                  <label className="form-check-label" htmlFor="inlineCheckbox2">
                    Scrubber
                  </label>
                </div>
              </div>
              <div className="mt-4 gap-2 mb-2">
                <p className="w-50">Vessel Image:</p>
                <div className="edit-file-input">
                  <img src={UploadIcon} alt="" />
                  <p className="mt-1 text-sm text-gray-600">
                    Browse and choose the files you want to upload from your
                    computer
                  </p>
                  <button className="upload-button" onClick={handleBrowseClick}>
                    Browse
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="edit-file-input outline-0"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              <div className="actions bg-none text-end">
                <button
                  className="btn btn-primary btn-lg fs-16"
                  onClick={handleUpdateVessel}
                >
                  Save
                  {showSpin && (
                    <div className="spinner-border" role="status"></div>
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
