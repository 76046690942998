export function formatNumber(num) {
  if (!num) return 0;
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { month: "short", day: "2-digit", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

export function roundUpToNearest(value) {
  const magnitude = Math.floor(Math.log10(value));
  const interval = Math.pow(10, magnitude - 1);
  const final = Math.ceil(value / interval) * interval;
  return Math.ceil(final);
}

export function capitalizeAllLetters(str) {
  if (typeof str !== "string") {
    return "";
  }
  return str.toUpperCase();
}
