import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Loading from "../Loading";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PowerCurveChart = ({ chartData }) => {
  const [labels, setLabels] = useState([]);
  const [dataset, setDataset] = useState([]);

  useEffect(() => {
    if (chartData && Object.keys(chartData).length > 0) {
      const dataLabels = Object.keys(chartData).map(Number);
      const dataValues = Object.values(chartData);

      const dataset = [
        {
          label: "Power Curve",
          data: dataValues,
          borderColor: "#71A4F6",
          backgroundColor: "#71A4F6",
          tension: 0.2,
          pointRadius: 1,
          borderWidth: 3,
        },
      ];
      setDataset(dataset);
      setLabels(dataLabels);
    }
  }, [chartData]);

  const dataPower = {
    labels: labels,
    datasets: dataset,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            const title = context[0].label;
            return `${title} kt`;
          },
          label: function (context) {
            const value = context.raw;
            return `  ${value.toFixed(2)} kW`;
          },
        },
      },
      legend: {
        display: false,
        rtl: true,
        position: "bottom",
        align: "center",
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          pointStyle: "circle",
          textAlign: "center",
          padding: 20,
          font: {
            lineHeight: 2,
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          font: "10px",
          lineHeight: "13px",
          callback: function (value) {
            return Math.floor(value);
          },
        },
        title: {
          display: true,
          text: "ME power [KW]",
          color: "#000000",
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          min: 0,
          callback: function (value, index) {
            return labels[index];
          },
        },
        title: {
          display: true,
          text: "Speed [kt]",
          color: "#000000",
        },
      },
    },
  };

  return (
    <div className="h-100">
      {dataset.length > 0 ? (
        <Line options={options} data={dataPower} />
      ) : (
        <div className="d-flex align-items-center justify-content-center h-100">
          <Loading loading={dataset.length === 0} height={30} />
        </div>
      )}
    </div>
  );
};

export default PowerCurveChart;
