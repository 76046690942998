import React, {  useEffect, useRef, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../assets/logo.svg";
import "../css/main.css";
import "../css/responsive.css";
import ZJoule from "../assets/zjoule.png";
import Avatar from 'react-avatar';
import { useSelector } from "react-redux";
import { getOwner } from "../api/data";
import CustomTooltip from "./CustomTooltip";

const NavbarComponent = ({
  show=false,
  showfaq = false
}) => {

  const infoModalref = useRef();
  const [showModal, setShowModal] = useState(false);
  const [backArrowLink, setBackArrowLink ] = useState("")
  const [appName, setAppName] = useState("")
  const [ownerInfo, setOwnerInfo] = useState(null);
  const [permissions, setPermissions] = useState(null);

  const canAccessRoute = (requiredPermission) => {
    return permissions.some(
      (permission) =>
        permission.licensePermission.permissionName === requiredPermission
    );
  };

  const truncateEmail = (email) => {
    if (!email) return '';
    return email.length > 30 ? `${email.slice(0, 30)}...` : email;
  };

  const fullEmail = ownerInfo?.email || '';


  useEffect( () =>{
    handleBackUrl();
  }, []);

  const getOwnerPermission = () => {
    getOwner()
      .then((result) => {
        setOwnerInfo(result.data);
        setPermissions(result.data.owner.permissions);
      })
      .catch((err) => console.error("err get company name", err));
  };
  

  const handleBackUrl = () => {
    const url = localStorage.getItem("appUrl")
    const appName = localStorage.getItem("appName")
    setBackArrowLink(url);
    setAppName(appName);
  }


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showModal &&
        infoModalref.current &&
        !infoModalref.current.contains(event.target)
      ) {
        setShowModal(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showModal, infoModalref]);


  return (
    <Navbar collapseOnSelect expand="lg bottom-border-1px p-0">
      <div className="nav-new-container">
        {appName === "pool" &&
          <Link className="navbar-brand d-flex align-items-center" to={backArrowLink}>
            <span className="span-lg">POOL.FM</span>
          </Link>
        } {appName === "sf2" &&
          <Link className="navbar-brand d-flex align-items-center" to={backArrowLink}>
            <img className="sitelogo" src={Logo} alt="" />
          </Link>
        }
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className= "mx-auto">
            <div className="z-joule-container">
                <p>
                  Powered by{" "}
                </p>
                <span>
                  <NavLink to="https://z-joule.com/" target="_blank">
                    <img src={ZJoule} alt="" />
                  </NavLink>
                </span>
            </div>
          </Nav>
          <Nav className="align-items-center">
            {showfaq && (
              <NavLink to="/faqs" target="_blank">
                <button className="btn btn-secondary faq-button" type="button">
                  FAQs
                </button>
              </NavLink>
            )}
            {/* {show &&
              <Menu curose menuButton={<a className="cursor-pointer"><img src={hamburgerIcon} alt="close" /></a>}>
                  <div
                    id="basic-menu"
                    className="hamburger-dropdown">
                    <div className="user-info" style={{ position: 'relative', width:'16rem', height: '120px' }}>
                      <div style={{
                          background: 'var(--z-joule-com-linear-gossamer-curious-blue, linear-gradient(90deg, #06966B 0%, #2C8EC6 100%))',
                          width: '100%',
                          height: '120px',
                          position: 'relative',
                          borderRadius: ".5rem .5rem 0 0"
                        }}
                        >
                      </div>
                      <div style={{
                            position: 'absolute',
                            top: '60%',
                          }}>
                        <Avatar name={ownerInfo?.firstname} src="/static/images/avatar/2.jpg" >
                        </Avatar>
                      </div>
                    
                    </div>
                    <div className="d-flex align-items-center flex-column w-100">
                      <div className="mt-4">
                        <span className="custom-user-info d-flex justify-content-center">{ ownerInfo?.firstname}</span>
                      </div>
                      <div className="pb-3 mt-3" style={{ borderBottom: '1px solid #DFDFDF' }}>
                    <div className="d-flex justify-content-center">

                      <CustomTooltip
                        tooltipText={ fullEmail.length > 25 ? fullEmail : '' } 
                        placement="top">
                          <span className="custom-user-info">{truncateEmail(fullEmail)}</span>
                      </CustomTooltip>
                    </div>
                  </div>
                </div>
                <ul className="d-grid settings-menu-window">
                  <li disabled={!canAccessRoute("EditRoutes")} onClick={() => alert("Edit Routes")}>
                      <img src={RouteEditorIcon} alt="" className="premium-icon" />
                      <span className="editor-menu-list-items" >Route Editor</span> 
                    </li>
                  <li onClick={() => navigate("/vessettypeeditor")}>
                      <img src={PriceForecastIcon} alt="" className="premium-icon" />
                      <span className="editor-menu-list-items">Vessel Type Editor</span>
                  </li>
                  <li onClick={() => navigate("/fuelpriceforecast")}>
                      <img src={PriceForecastIcon} alt="" className="premium-icon" />
                      <span className="editor-menu-list-items">Fuel Prices Forecast </span>
                  </li>
                  <li disabled={!canAccessRoute("EditLegislativeScenarios")} onClick={() => navigate("/legislativescenario")}>
                      <img src={RegulatoryContextIcon} alt="" className="premium-icon" />
                      <span className="editor-menu-list-items"> Legislative Scenarios </span>
                  </li>
                  <a href={scenarioLink} >
                    <li disabled={!canAccessRoute("EditLegislativeScenarios")} onClick={() => handleNavigation("legislativescenario")}>
                        <img src={RegulatoryContextIcon} alt="" className="premium-icon" />
                        <span className="editor-menu-list-items">Legislative Scenarios </span>
                    </li>
                  </a>
                </ul>
                <ul className="d-grid settings-menu-window" style={{ borderTop: '1px solid #DFDFDF' }}>
                    <li disabled={true }  onClick={() => setShowModal((prevShowModal) => !prevShowModal)} ref={infoModalref}>
                      <img src={SettingsIcon} alt="" className="premium-icon" />
                      <span className="editor-menu-list-items">Settings</span>
                  </li>
                  <li onClick={handleLogOut}>
                      <img src={LogoutIcon} alt="" className="premium-icon" />
                      <span className="editor-menu-list-items">Log Out</span>
                  </li>
                </ul>
              </div>
            </Menu>} */}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default NavbarComponent;
